<template>
  <vs-row vs-justify="center">       
    <vs-col vs-lg="12" vs-xs="12" vs-sm="12">
      <vs-card>
        <!-- Header -->
          <div class="d-md-flex align-items-center">
              <!--<div>
      <h3 class="card-title">Projects of the Month</h3>
      <h5 class="card-subtitle text-muted font-weight-normal">Overview of Latest Month</h5>
    </div>-->
              <div class="ml-auto">
                  <vs-select class="w-100px" v-model="filter1">                 
                      <vs-select-item :key="index"
                                      :value="item.value"
                                      :text="item.text"
                                      :is-selected.sync="item.isSelected"
                                      v-for="(item,index) in filteroptions1" />
                  </vs-select>
              </div>
          </div>
        <SurveyTable :filtro="filter1" :key="filter1"></SurveyTable>
      </vs-card>
    </vs-col>  
  </vs-row>
</template>
<script>
// //////////////////////////////////////////
// Import Components Here
// //////////////////////////////////////////    
    
import SurveyTable from "../survey-table/SurveyTable.vue"

// //////////////////////////////////////////
// Export Here
// //////////////////////////////////////////
export default {
  name: "Surveys",
  data: () => ({
    title: "Surveys",
    // Month Table
    filter1: 0,
    filteroptions1: [
        { text: "2019", value: "2019", isSelected:true },
        { text: "Todos", value: "Todos", isSelected: false },
        { text: "Anteriores", value: "Outros", isSelected: false },      
    ]
  }),
  components: {
    SurveyTable,    
        },
  created() {
             this.setSideBar(true);
        },
         methods: {
             setSideBar(val) {
            this.$store.commit("TOGGLE_REDUCE_SIDEBAR", val);
          }
        }
};
</script>