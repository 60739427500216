<template>
    <div>
        <div v-bar class="vs-scrollable" style="height: 393px;">
            <div>                
                <vs-table :data="SurveyList" class="text-nowrap">
                    <template slot="thead">
                        <vs-th>Nome</vs-th>
                        <vs-th>Code</vs-th>
                        <vs-th>City</vs-th>
                        <vs-th>Country</vs-th>
                    </template>

                    <template slot-scope="{data}">
                        <vs-tr class="border-top" :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td>
                                <div class="d-flex no-block align-items-center">
                                    <div>
                                        <router-link :to="getRoute(data[indextr].survey_id)"> <h5 class="mb-0 font-16 font-medium">{{ data[indextr].estadio_name }}</h5></router-link>
                                    </div>
                                </div>
                            </vs-td>
                            <vs-td>{{ data[indextr].estadio_code }}</vs-td>
                            <vs-td>{{ data[indextr].city }}</vs-td>
                            <vs-td><span class="font-medium"><img :src="getflag2(data[indextr].country_id)" width="30" /> {{ data[indextr].country_name_original }}</span></vs-td>
                            <!--
                                    <vs-td>
                <div class="d-flex align-items-center">
                    <vs-tooltip :text="data[indextr].statustext" :color="data[indextr].statuscolor">
                        <i class="mdi mdi-checkbox-blank-circle" :class="[data[indextr].status]"></i>
                    </vs-tooltip>
                </div>
                                        </vs-td>
                                            -->
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
        </div>
    </div>
</template>
<script>

    import {Surveys} from "../../../services/Arena.js";
    const sv = new Surveys;

    export default {
            name: "SurveyTable",
      data: () => ({
            title: "SurveyTable",

        // Data for data[indextr]
        SurveyList: [
        ]
        }),
        props: {
            filtro: { type: String }            
        },
        created() {
            if (!this.filtro)
                this.filtro = "Todos";
            this.$vs.loading();
            
            sv.Get(this.filtro).then((resp) => {
                this.SurveyList = resp;
                this.$vs.loading.close()
            }, (err) => this.errors.push(err));

        },
        methods: {
            //
            getRoute: function (id) {
                var s = "";
                s = `/surveys/struct/${id}`;
                return s;
            },
            getflag2(id) {
                var s = "";
                s = `/img/countries/${id}.png`;
                return s;
            }
        }
};
</script>
